<template>
  <div class="_bg-default _80w _100vh px-5 py-3">
    <div class="_100top">
      <v-card class="radius-card pa-3" min-height="200px">
        <div v-if="soal && !loading" class="pa-6">
          <div class="d-flex align-center">
            <v-progress-linear
              color="deep-purple accent-4"
              :value="percent"
              rounded
              class="mr-2"
              height="10"
            ></v-progress-linear>
            <v-tooltip nudge-top="-3" top color="blue">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  fab
                  depressed
                  class="purple"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="dialog = true"
                >
                  <v-icon>mdi-help-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>Instruksi</span>
            </v-tooltip>
          </div>
          <div>
            <small class="grey--text ml-3"
              >{{ soal[0].no_soal }}/{{ total }}</small
            >
            <div>
              <div class="pb-3 mb-5">
                <h4 class="text-center soal__">
                  {{ soal[0].pertanyaan }}
                </h4>
              </div>

              <v-card
                v-for="(opt, idx) in soal[0].pilihan"
                :key="idx"
                active-class="purple"
                class="pa-2 my-3 __opt"
                :color="opt.picked ? 'purple' : '#ECEFF1'"
                v-ripple="{ class: `purple--text` }"
                @click.native="accommodateAnswer(opt.index, soal[0].id)"
                ><p
                  :class="
                    opt.picked
                      ? 'text-center ma-0 white--text'
                      : 'text-center ma-0'
                  "
                >
                  {{ opt.pernyataan }}
                </p>
              </v-card>
            </div>
          </div>
          <div class="d-flex justify-center mt-5">
            <v-btn
              class="text-capitalize"
              dark
              color="purple"
              small
              @click="paginate('prev')"
              >prev</v-btn
            >
            <p class="ma-0 purple--text mx-3">
              {{ soal[0].no_soal }}/{{ total }}
            </p>
            <v-btn
              v-if="answer.length < 7"
              class="text-capitalize"
              :dark="isBack"
              :disabled="!isBack"
              color="purple"
              small
              @click="paginate('next')"
              >Next</v-btn
            >
            <v-btn
              v-if="answer.length == 7"
              class="text-capitalize"
              color="purple"
              small
              dark
              @click="finishTest()"
              >Finish</v-btn
            >
          </div>
          <div>
            <alert-quiz
              v-bind:dialogAlert="dialogAlert"
              v-bind:loading="loadStore"
              @store="storeAnswer"
              @close="dialogAlert = false"
              @refetch="loadQuestion"
            />
            <alert-result-quiz
              v-bind:dialogResult="dialogResult"
              @close="dialogResult = false"
              v-bind:result="result"
            />
          </div>
        </div>
        <v-overlay color="white" absolute :value="loading">
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="purple"
          ></v-progress-circular>
          <b class="color_default">Loading...</b>
        </v-overlay>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import alertQuiz from "../Modal/alertQuiz.vue";
import AlertResultQuiz from "../Modal/alertResultQuiz.vue";
export default {
  name: "gadQuiz",
  data() {
    return {
      id: 1,
      answer: [],
      soal: null,
      dialogResult: false,
      no_soal: null,
      page: 1,
      isBack: false,
      limit: 1,
      curent: 1,
      dialogAlert: false,
      total: 0,
      done: false,
      percent: 0,
      last_page: 0,
      loading: false,
      loadReq: false,
      lobby: true,
      dialog: false,
      loadStore: false,
      result: null,
    };
  },
  components: { alertQuiz, AlertResultQuiz },
  computed: {
    ...mapState({
      profile: (state) => state.cust.myProfile,
    }),
  },
  // eslint-disable-next-line no-unused-vars
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem("isWorkingOn");
    next();
  },
  mounted() {
    this.loadQuestion(true);
    if (localStorage.getItem("isWorkingOn")) {
      //kalo peserta refresh di tengah pengerjaan soal, cek kalo ada ini instruksinya di false
      this.lobby = false;
    }
  },
  methods: {
    startTest() {
      //fungsi buat start test
      this.lobby = false;
      localStorage.setItem("isWorkingOn", true); //ini buat nandai kalo peserta lagi ngerjain, buat kebutuhan hanle refresh juga
    },

    loadQuestion(first) {
      if (first) {
        this.loading = true;
      }
      let body = {
        id: this.id,
        page: this.page,
        limit: this.limit,
      };
      this.$store
        .dispatch("quiz/getQuestion", body)
        .then((data) => {
          this.loading = false;
          this.soal = data.data;
          this.current = data.current_page;
          this.total = data.total;
          this.last_page = data.last_page;
          this.checkPicked(this.soal);
          this.checkNext(this.soal[0]);
          this.percent = (this.page / this.total) * 100;
          this.done = this.current == this.total ? true : false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    checkPicked(soal) {
      if (this.answer.length) {
        soal.forEach((el) => {
          this.answer.forEach((answer) => {
            if (el.id === answer.id) {
              el.pilihan.forEach((opt) => {
                if (opt.index === answer.index) {
                  opt["picked"] = true;
                } else {
                  opt["picked"] = false;
                }
              });
            } else {
              this.isBack = false;
            }
          });
        });
      } else {
        soal.forEach((el) => {
          el.pilihan.forEach((opt) => {
            opt["picked"] = false;
          });
        });
      }
    },
    paginate(state) {
      if (state == "next") {
        if (this.done) {
          this.finishTest();
        } else {
          this.page++;
        }
        this.loadQuestion();
      } else {
        if (this.page > 1) {
          this.page--;
          this.loadQuestion();
        }
      }
    },
    finishTest() {
      this.dialogAlert = true;
    },
    checkNext(soal) {
      let answer = this.answer.filter((value) => value.id == soal.id);
      if (answer.length) {
        this.isBack = true;
      } else {
        this.isBack = false;
      }
    },
    accommodateAnswer(idx, id) {
      let answer = this.answer.filter((value) => value.id == id);
      if (answer.length) {
        let index_opt = this.answer.findIndex((el) => {
          el.id == id;
        });
        this.answer.splice(index_opt, 1);
        this.temporarySave(idx, id);
      } else {
        this.temporarySave(idx, id);
      }
      if (this.done) {
        this.dialogAlert = true;
      }
    },
    temporarySave(idx, id) {
      this.answer.push({
        id: id,
        index: idx,
      });
      localStorage.setItem("answer", JSON.stringify(this.answer));
      this.paginate("next");
    },
    storeAnswer() {
      this.loadStore = true;
      let answer = {};
      this.answer.map((item) => {
        answer[item.id] = item.index;
      });
      let body = {
        jawaban: JSON.stringify(answer),
        email: this.profile.email,
      };
      let data = {
        id: this.id,
        body: body,
      };
      this.$store
        .dispatch("quiz/storeAnswer", data)
        .then((data) => {
          console.log(data);
          this.getResult();
        })
        .catch((err) => {
          this.loadStore = false;
          console.log(err);
        });
    },
    getResult() {
      this.$store.dispatch("quiz/getResult", "gad").then((data) => {
        console.log(data);
        this.loadStore = false;
        this.dialogAlert = false;
        this.result = data.data.hasil.deskripsi;
        this.dialogResult = true;
      });
    },
  },
};
</script>

<style scoped>
.__main_page {
  width: 40%;
  margin: auto;
}
.__opt {
  cursor: pointer;
}
.__temp_brain {
  width: 100%;
  border-radius: 8px;
  height: 250px;
  background: white;
}
.otak {
  z-index: 2;
  margin-left: -3px;
}
.fill__brain {
  width: 140px;
  background: #f8bbd0;
}
.__temp_count {
  z-index: 3;
  margin-left: -120px;
  margin-top: 30px;
}
.soal__ {
  font-size: 20px;
}
.iq {
  color: #9c27b0;
}
.classification {
  padding: 3px 15px;
  background: #f3e5f5;
  border-radius: 10px;
}
.header__ {
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("~/assets/img/kecerdasan.jpeg"); */
  height: 150px;
  background-position: center;
  background-size: cover;
}
h1 {
  -webkit-text-stroke: 1px black;
  font-weight: 500;
  font-size: 50px;
}
@media (max-width: 576px) {
  .__main_page {
    padding: 0 10px;
    width: 100%;
  }
}
@media (max-width: 882px) {
  .__main_page {
    width: 100%;
  }
}
</style>
