<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAlert" persistent max-width="370px">
      <v-card class="radius-card hide_overflow" flat>
        <div
          class="d-flex flex-column align-center justify-center"
          style="height: 230px"
        >
          <div>
            <div class="px-5">
              <p class="small_txt color_txt text-center">
                Yakin ingin menyelesaikan tes?
              </p>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                @click="$emit('store')"
                class="text-capitalize py-6 mr-4"
                dark
                color="purple"
                width="100px"
                depressed
                >Ya</v-btn
              >
              <v-btn
                class="text-capitalize py-6"
                @click="cancelStore"
                outlined
                color="purple"
                width="100px"
                depressed
                >Batal</v-btn
              >
            </div>
          </div>
          <v-overlay
            :absolute="true"
            color="white"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="purple"
            ></v-progress-circular>
            <b class="purple--text">Loading...</b>
          </v-overlay>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "alertQuiz",
  props: ["dialogAlert", "loading"],

  data() {
    return {
      role: ""
    };
  },
  methods: {
    cancelStore() {
      this.$emit("close");
      this.$emit("refetch");
    }
  }
};
</script>
