<template>
  <v-row justify="start">
    <v-dialog v-model="dialogResult" persistent max-width="370px">
      <v-card class="radius-card hide_overflow" flat v-if="result">
        <div
          class="d-flex flex-column align-center justify-center"
          style="height: 230px"
        >
          <div class="px-5 mb-10">
            <p class="small_txt color_txt text-center">Hasil tes Anda</p>
            <h1 class="text-center">{{ result }}</h1>
          </div>
          <div>
            <v-btn
              class="py-7 text-capitalize"
              @click="backTo"
              depressed
              block
              dark
              color="purple"
              >Kembali ke halaman quiz</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "alertQuiz",
  props: ["dialogResult", "result"],

  data() {
    return {
      role: ""
    };
  },
  methods: {
    backTo() {
      this.$emit("close");
      this.$router.push("/user/quiz");
    }
  }
};
</script>
